import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";



export const genrateDemoOrderServices = createAsyncThunk(
    "genrateDemoOrderServices",
    async () => {
      try {
        let url = `${BASE_URL}/order/generate-demo-order`;
        let obj={}
        const res = await axios.post(url,obj);
        console.log(res);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );


  export const genrateSubscripitionOrderServices = createAsyncThunk(
    "genrateDemoOrderServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/order/generate-subscription-order`;
        let obj={}
        const res = await axios.post(url,payload);
        console.log(res);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );


  export const paymentSucessServices = createAsyncThunk(
    "paymentSucessServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/subscription/create-subscription`;
        let obj={}
        const res = await axios.post(url,payload);
        console.log(res);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );


  export const liveOfferServives = createAsyncThunk(
    "liveOfferServives",
    async () => {
      try {
        let url = `${BASE_URL}/offer/offer-list`;
        const res = await axios.get(url);
        console.log(res);
        return res.data;
      } catch (error) {
        handleError(error)
        throw error;
      }
    }
  );



  export const walletAmountServices2 = createAsyncThunk(
    "walletAmountServices2",
    async (subscriptionAmount) => {
      try {
        let url = `${BASE_URL}/static-data/wallet-deduction-amount?subscriptionAmount=${subscriptionAmount}`;
        const res = await axios.get(url);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );


  export const setMaximumPercentUseServices = createAsyncThunk(
    "setMaximumPercentUseServices",
    async (payload) => {
      try {
        let url = `${BASE_URL}/static-data/wallet-setting-details`;
        const res = await axios.get(url,payload);
        return res.data;
      } catch (error) {
        handleError(error);
        throw error;
      }
    }
  );