import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Utils/Axios";
import { BASE_URL } from "../../Utils/Urls";
import { handleError } from "../../Services/ErrorHandlerServices";
export const PlanListServices = createAsyncThunk(
    "PlanListServices",
    async () => {
      try {
        let url = `${BASE_URL}/subscription-plan/subscription-plan-list`;
        const res = await axios.get(url);
        console.log(res);
        return res.data;
      } catch (error) {
        throw error;
      }
    }
  );




  
export const getDefaultPlanServices = createAsyncThunk(
  "getDefaultPlanServices",
  async (payload) => {
    try {
      let url = `${BASE_URL}/static-data/plan-setting-details`;
      const res = await axios.get(url,payload);
      return res.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);